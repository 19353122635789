import api from '@/libs/axios'
import qs from 'qs'
//import qs from "qs"

const endpoints = {
    role: '/data/roles',
    permissions: '/authorization/permissions',
    corporate: '/corporates',
    state: '/states',
    nrc_township: '/nrcs',
    facility_type: '/data/facility-types',
    country_code: '/country-codes',
    emr: {
        doctor: '/doctors/byemrid',
        speciality: '/specialities/byemrid',
        place: '/data/emr-places',
        payer_list: '/portal-patients/byemrid'
    },
    facility: '/facilities',
    nationality: '/nationality',
    title: '/patient-title',
    doctors: '/doctors',
    reason: '/data/reasons',
    symptom: '/symptoms',
    doctor_timeslot: '/data/current-available-timeslot',
    doctor_call_action: '/communication/doctor/action',
    upcoming_setting_time: 'data/get-upcoming-setting-time',
    patient: '/portal-patients',
    package: '/packages/patient-packages',
    new_support_chat: '/notifications/new-support-chat',
    city: '/cities',
    allPackages: '/packages',
    content_list: `/content/list`,
    patient_count: '/data/count',
    medicalStaffType: '/doctors/medical-staff-types',
    speciality: '/specialities/bymedicalstaff',
    category: '/categories',
    chat_channel: '/chat_channel'
}

const globalService = {
    getAllRole: async (params) => {
        try {
            const result = await api.get(endpoints.role, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllPermission: async (params) => {
        try {
            const result = await api.get(endpoints.permissions, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllCorporate: async (params) => {
        try {
            const result = await api.get(endpoints.corporate, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllState: async (params) => {
        try {
            const result = await api.get(endpoints.state, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllNrcTownship: async (params) => {
        try {
            const result = await api.get(`${endpoints.nrc_township}/${params.state_id}`)
            return result.data
        } catch {
            return null
        }
    },
    getAllFacilityType: async (params) => {
        try {
            const result = await api.get(endpoints.facility_type, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllCountryCode: async (params) => {
        try {
            const result = await api.get(endpoints.country_code, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllEMRDoctor: async (params) => {
        try {
            const result = await api.get(endpoints.emr.doctor, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllEMRPayerList: async (params) => {
        try {
            const result = await api.get(endpoints.emr.payer_list, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllEMRSpeciality: async (params) => {
        try {
            const result = await api.get(endpoints.emr.speciality, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllFacility: async (params) => {
        try {
            const result = await api.get(endpoints.facility, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllEMRPlace: async (params) => {
        try {
            const result = await api.get(endpoints.emr.place, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllNationality: async (params) => {
        try {
            const result = await api.get(endpoints.nationality, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllTitle: async (params) => {
        try {
            const result = await api.get(endpoints.title, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllDoctor: async (params) => {
        try {
            const result = await api.get(endpoints.doctors, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getDoctorsBySpeciality: async (params) => {
        try {
            const result = await api.get(`${endpoints.doctors}/speciality`, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllReason: async (params) => {
        try {
            const result = await api.get(endpoints.reason, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllSymptom: async (params) => {
        try {
            const result = await api.get(endpoints.symptom, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getDoctorTimeslot: async (params) => {
        try {
            const result = await api.get(endpoints.doctor_timeslot, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getDoctorCallAction: async (params) => {
        try {
            const result = await api.post(endpoints.doctor_call_action, params)
            return result.data
        } catch {
            return null
        }
    },
    newSupportChat: async (params) => {
        try {
            const result = await api.post(endpoints.new_support_chat, params)
            return result.data
        } catch {
            return null
        }
    },
    getUpcomingSettingTime: async () => {
        try {
            const result = await api.get(endpoints.upcoming_setting_time)
            return result.data
        } catch {
            return null
        }
    },
    getAllPatient: async (params) => {
        try {
            const result = await api.get(endpoints.patient, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getPatientPackage: async (params) => {
        try {
            const result = await api.get(endpoints.package, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllCity: async (params) => {
        try {
            const result = await api.get(endpoints.city, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getServicesPackage: async (params) => {
        try {
            const result = await api.get(`${endpoints.allPackages}/${params.id}/show`)
            return result.data
        } catch {
            return null
        }
    },
    getAllPackages: async (params) => {
        try {
            const result = await api.get(endpoints.allPackages, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    },
    getAllContents: async (params) => {
        try {
            const result = await api.get(`${endpoints.content_list}`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })
            return result.data
        } catch {
            return null
        }
    },
    getPatientsCount: async (params) => {
        try {
            const result = await api.get(`${endpoints.patient_count}`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })
            return result.data
        } catch {
            return null
        }
    },
    getMedicalStaffType: async (params) => {
        try {
            const result = await api.get(`${endpoints.medicalStaffType}`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })
            return result.data
        } catch {
            return null
        }
    },
    getSpecialityByMedicalStaff: async (params) => {
        try {
            const result = await api.get(`${endpoints.speciality}`, {
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            })
            return result.data
        } catch {
            return null
        }
    },
    getCategory: async (params) => {
        try {
            const result = await api.get(`${endpoints.category}`, {
                params: params
                // paramsSerializer: (params) => {
                //     return qs.stringify(params)
                // }
            })
            return result.data
        } catch {
            return null
        }
    },
    getChatChannel: async (params) => {
        try {
            const result = await api.get(`${endpoints.chat_channel}`, {
                params: params
            })
            return result.data
        } catch {
            return null
        }
    }
}

export { globalService }
